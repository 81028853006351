import { setColourMode } from './scripts/colour-mode';
import { handleGalleryImages } from './scripts/gallery-images';
import { localDev } from './scripts/local-dev';
import { noscriptHandler } from './scripts/no-script';
import { setHeader } from './scripts/setup-header';
import { svgDiagrams } from './scripts/setup-mermaid-diagrams';
import { sortableTables, wrapTables } from './scripts/setup-tables';
import { syncAnimations } from './scripts/sync-animations';
import { checkWebmentions } from './scripts/check-webmentions';
import { handlePostImages } from './scripts/post-images';
import { importGoat } from './scripts/import-goat';
import { setupRecentPosts } from './scripts/recent-posts';
import { setupCatCount } from './scripts/cat-count/cat-count';

/** This file blocks rendering and will run before any dom content loads, call heavy functions in a listener! */

setColourMode();

document.addEventListener('DOMContentLoaded', async function () {
  noscriptHandler();
  handleGalleryImages();
  wrapTables();
  setHeader();
  sortableTables();
  svgDiagrams();
  localDev();
  syncAnimations();
  handlePostImages();
  importGoat();
  await Promise.all([checkWebmentions(), setupRecentPosts(), setupCatCount()]);
});
