import { e, getElementsByClassName, skeleton } from './utils';

export const setupRecentPosts = async () => {
  const recentPosts =
    getElementsByClassName<HTMLDivElement>('recentPosts')?.[0];

  recentPosts?.append(skeleton('100%', '500px'));

  if (recentPosts) {
    const rssXML = await fetch('/rss.xml');
    const rssXMLText = await rssXML.text();
    const rssXMLDoc = new DOMParser().parseFromString(rssXMLText, 'text/xml');
    const items = rssXMLDoc.querySelectorAll('entry');
    recentPosts.innerHTML = '';

    const posts = [...(items || [])]
      .map((item) => {
        const postTitle = item.querySelector('title')?.innerHTML as string;
        const postLink = item.querySelector('link')?.innerHTML as string;
        const postEmoji = item.querySelector('emoji')?.innerHTML as string;
        const postDate = new Date(
          item.querySelector('published')?.innerHTML as string,
        );

        return {
          title: postTitle,
          emoji: postEmoji,
          link: postLink,
          date: postDate,
        };
      })
      .filter((post) => {
        return post.date.valueOf() > 0;
      });

    recentPosts.parentNode?.insertBefore(
      e('h2', {}, 'Recent pages'),
      recentPosts,
    );

    posts.forEach((post) => {
      recentPosts.append(
        e('li', {}, [
          `${post.emoji || '📝'} `,
          e('a', { href: post.link.replace('https://pfy.ch', '') }, [
            e('span', {}, [
              e(
                'span',
                { className: 'date' },
                `${post.date.toLocaleDateString()} - `,
              ),
              post.title.replaceAll('&amp;', '&'),
            ]),
          ]),
        ]),
      );
    });
  }
};
